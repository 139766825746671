import React, { useState} from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Logo from '../imgs/logo.png'
import "../css/navbar.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faBarsStaggered, faChevronDown} from "@fortawesome/free-solid-svg-icons";


const CustomNavbar = () => {

    return (
        <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: "rgb(255,255,255)",   boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"  }}>
            <Navbar.Brand style={{ color: "rgba(61, 61, 61, 0.831372549)", fontWeight: "600"}} href="/">
               <div className="d-flex flex-row align-items-center">
                   <img
                       src={Logo}
                       height="40"
                       style={{ paddingLeft: "20px" , paddingRight: "20px"}}
                       className="d-inline-block align-top "
                       alt="Logo"
                   />  <div className="logo-name"> Biodanzaschule Freiburg</div>
               </div>

            </Navbar.Brand>
            <Navbar.Toggle style={{ marginRight: "20px"}} aria-controls="responsive-navbar-nav">
                <FontAwesomeIcon icon={faBarsStaggered}  />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav style={{ paddingRight: "15px" }}>
                    <NavDropdown
                        title={
                            <span>
                Biodanza <FontAwesomeIcon icon={faChevronDown} size="2xs" />
              </span>
                        }
                        id="biodanza"
                    >
                        <NavDropdown.Item href="/biodanza">Was ist Biodanza</NavDropdown.Item>
                        {/*<NavDropdown.Item href="#action/3.18">Was ist Biodanza</NavDropdown.Item>*/}
                        <NavDropdown.Item href="/das-biozentrische-system">Das Biozentrische System</NavDropdown.Item>
                    </NavDropdown>
                    {/*<Nav.Link href="/Mitmachen">Mitmachen</Nav.Link>*/}
                    <NavDropdown  title={
                        <span>
                Ausbildung <FontAwesomeIcon icon={faChevronDown} size="2xs" />
              </span>
                    } id="ausbildung"
                        > <NavDropdown.Item href="/ein-Beruf-mit-Zukunft">Ein Beruf mit Zukunft</NavDropdown.Item>
                        <NavDropdown.Item href="/biodanzaschuleFreibung">Biodanzaschule Freiburg</NavDropdown.Item>
                        <NavDropdown.Item href="/ausbildung">Ausbildung</NavDropdown.Item>

                        <NavDropdown.Item href="/ausbildungskalender">Ausbildungskalender 2024-2027</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/schulleitungBeateHoffman">Über mich</Nav.Link>
                    {/*<Nav.Link href="/events">Events</Nav.Link>*/}
                    <Nav.Link href="/links">Links</Nav.Link>
                    <Nav.Link href="/kontakt">Kontakt</Nav.Link>

                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default CustomNavbar;




