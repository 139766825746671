
import CookieConsent from "react-cookie-consent";


export default function CookieBanner() {
    return (
        <div><CookieConsent
            location="bottom"
            buttonText="Ok"
            cookieName="CookieBanner"
            style={{ background: "#2B373B", fontSize: "15px", textAlign: "center", justifyContent: "center" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px", fontWeight: "600", backgroundColor: "#d5887e", borderRadius: "7px" }}
            expires={150}
            className="d-flex "
        >
            {/* Diese Website verwendet Cookies. Durch die Nutzung dieser Website akzeptieren Sie die Benutzung von Cookies. */}

            Wir verwenden nur notwendige Cookies, um die Funktion unserer Website sicherzustellen. Durch die Nutzung unserer Website stimmen Sie der Verwendung von notwendigen Cookies zu.

            {/* Weitere Informationen finden Sie in unserer Datenschutzerklärung. */}

            {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
        </CookieConsent></div >
    )
}





