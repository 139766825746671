import Logo from "../imgs/Logo_Hari_eigen.jpg"
import "../css/article.scss"
import { ReadMoreButton } from "../UI/Buttons";

export const ArticleAusbildung = () => {
    return (
        <div className="d-flex center">

            <div className="article-container d-flex center">
                {/*<div className="article-header d-flex center">*/}
                {/*    <img src={Logo} alt="Logo" style={{height: "200px"}}/>*/}
                {/*</div>*/}
                <div className="title-with-image-container">

                    <h2 className="title">
                        <span className="image-text">Ausbildung </span>
                    </h2>
                </div>

                <div className="article-content center">
                    <p>
                        Die Biodanza-Schule Freiburg bietet eine theoretische, methodische und erlebnisorientierte Ausbildung, die durch langjährige Forschung perfektioniert wurde und qualifiziert zum/zur Biodanza Lehrer:in mit einem international durch die IBFed anerkannten Diplom. Gemäß den methodischen Grundlagen von Professor Rolando Toro und dem Einheitlichen Ausbildungsprogramm besteht das Ziel der Schule darin, eine qualitativ hochwertige Ausbildung anzubieten, die für die Ausübung des Berufs des Biodanza-Lehrers geeignet ist.
                    </p>


                    <ReadMoreButton link={"/ausbildung"} />

                </div>



            </div>
        </div>
    );
};


