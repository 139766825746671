import Logo from "../imgs/Logo_Hari_eigen.jpg";
import React from "../index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-solid-svg-icons";
import {useLocation} from "react-router-dom";

export const Footer = () => {

    // const location = useLocation(); // Getting the current location
    //
    // // Conditionally rendering the footer-pic based on the path
    // const renderFooterPic = location.pathname !== "/kontakt";
    //


    return (
        <div>
            {/*{renderFooterPic && ( // Rendering footer-pic only if renderFooterPic is true*/}
                <div className="footer-pic">
                </div>
            {/*)}*/}

        <div className="footer center">

            {/*<div className=" center">*/}
            {/*    <img src={Logo} alt="Logo" style={{height: "100px"}}/>*/}
            {/*</div>*/}

            <div className="pt-3">
                <a
                href="mailto:info@biodanzaschule-freiburg.de"> info@biodanzaschule-freiburg.de</a>
            </div>


                <div className="">Copyright © 2024 Biodanzaschule
                    Freiburg
                </div>

<div className="d-flex flex-row ">
    <a href="/impressum"
       style={{paddingRight: "20px"}}>Impressum</a>
    <a href="/datenschutzerklaerung"
       className="">Datenschutzerklärung</a>
</div>

            <div className="text-center">


                Created with <FontAwesomeIcon icon={faHeart} style={{color: "rgb(247 192 168)",}} /> by
                <a style={{ color: "white", marginLeft: "6px"}} href="https://www.linkedin.com/in/joanna-cwalina/" rel="noreferrer" target="_blank" >Joanna Cwalina</a>
            </div>

        </div>
</div>
    )


}