import React, { useState, useRef } from 'react';
import "../css/newsletter.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios';
import emailjs from '@emailjs/browser';

const Newsletter = () => {
    const form = useRef();
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [subscribed, setSubscribed] = useState(false);
    const [error, setError] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState('');


    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_xulgmhk', 'template_dqehj6p', form.current, {
                publicKey: 'HFShCuoJcxkJ_3wKT',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!recaptchaToken) {
            setError('Bitte vervollständigen Sie das reCAPTCHA.');
            return;
        }
        try {
            const response = await axios.post('https://node-biodanza-freiburg.netlify.app/.netlify/functions/app/subscribe', {
                email,
                firstName,
                lastName,
                phoneNumber
            });
            if (response.data.success) {
                setSubscribed(true);
                sendEmail(e);

            }
        } catch (error) {
            console.error('Error subscribing:', error);
            if (error.response && error.response.data.message) {
                setError(error.response.data.message);
            } else {
                setError('Fehler beim Abonnieren. Bitte versuchen Sie es später erneut.');
            }
        }


    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
        setError('');
    };

    const key = process.env.REACT_APP_SITE_KEY;

    return (
        <div className="center newsletter-bg ">
            <div style={{ margin: "100px 0", backgroundColor: "transparent" }} className="  d-flex flex-column  ">

                <h3 className='image-text'>
                    NEWSLETTER
                </h3>
                {!subscribed ? (
                    <form className="d-flex flex-column align-items-center" ref={form} onSubmit={handleSubmit}>

                        <p className="newsletter-subtitle" style={{ color: "grey", fontSize: "18px", marginTop: "10px" }}>Trag dich hier ein, um regelmäßig Informationen über alle kommenden Veranstaltungen zu erhalten.</p>

                        <div className="form__group field">
                            <input type="input"
                                className="form__field"
                                placeholder="Vorname" name="vorname" id='vorname'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required />
                            <label htmlFor="vorname" className="form__label">Vorname</label>
                        </div>

                        <div className="form__group field">
                            <input type="input"
                                className="form__field"
                                placeholder="Nachname" name="nachname" id='nachname'
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required />
                            <label htmlFor="nachname" className="form__label">Nachname</label>
                        </div>

                        <div className="form__group field">
                            <input type="nummer"
                                className="form__field"
                                placeholder="Telefonnummer" name="phone" id='phone'
                                // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)} />
                            <label htmlFor="phone" className="form__label">Telefonnummer (optional)</label>
                        </div>

                        <div className="form__group field">
                            <input type="email"
                                className="form__field"
                                placeholder="Your Email" name="email" id='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required />
                            <label htmlFor="email" className="form__label">Deine Email</label>
                        </div>

                        <div style={{ marginTop: "20px" }}>
                            <ReCAPTCHA sitekey={key} onChange={handleRecaptchaChange} />

                        </div>

                        {error && <p style={{ color: "#c35f5f", fontSize: "18px" }}>{error}</p>}

                        <button type="submit" className="readMoreButton ">
                            Abonnieren <FontAwesomeIcon icon={faArrowRight} />
                        </button>



                    </form>
                ) : (
                    <p>Vielen Dank für deine Anmeldung!</p>
                )}



                <div className="center">
                    <p className="newsletter-unsubscribe" >
                        Die Email-Adresse wird ausschliesslich benutzt um unseren Newsletter und Informationen der
                        Biodanza-Schule Freiburg zu versenden und nicht an Dritte weitergegeben. Jederzeit kann der
                        Newsletter mit dem Unsubscribe / Abmelden Link abbestellt werden.
                    </p>
                </div>

            </div>

        </div>
    );
};

export default Newsletter;
